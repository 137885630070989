import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import NotationGroup from "../../../../../components/notation-group"
import NotationSolution from "../../../../../components/notation-solution"
import SprachmelodienNotation from "../../../../../content/kurse/reiseerinnerung/02-dichterwerkstatt/sprachmelodien-notation"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"
import Score from "../../../../../components/score"
import Paragraph from "../../../../../components/paragraph"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const { getStatsForTaskWithDict, answerStatusForDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query AlliterationenLoesungQuery {
      poster: file(
        relativePath: { eq: "kurse/poster-ueber-hoelderlins-laute.png" }
      ) {
        ...largeImage
      }
    }
  `)

  const task = {
    courseId: "reiseerinnerung",
    chapterId: "02-dichterwerkstatt",
    taskId: "alliterationen",
  }
  const comparator = ({ answer, solution, status }) => {
    if (answer?.active === true && solution === true) {
      return status.VALID_CHECKED
    }
    if (answer?.active === true && solution !== true) {
      return status.INVALID
    }
    if (solution && !answer?.active) {
      return status.MISSED
    }
    return status.VALID_UNCHECKED
  }
  const answers = answerStatusForDict({
    ...task,
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...task,
    comparator,
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/reiseerinnerung/02-dichterwerkstatt/hoelderlin-sprechen"
        />
      }
    >
      <Seo title="Alliterationen erkennen" />
      <Stack>
        <FeebackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Paragraph>
          Das Wehen des Windes wird durch die häufige Verwendung von Wörtern mit
          w- und f-Anlauten in den Versen selbst hörbar. Die Reihung von Wörtern
          mit dem Anlaut g sorgt hingegen dafür, dass Bewegung ins Gedicht
          hineinkommt. Dieses Spiel mit gleichklingenden Anlauten nennt man eine
          Alliteration.
        </Paragraph>
        <TaskSidebar
          main={
            <NotationGroup initialState={answers}>
              <SprachmelodienNotation notationType={NotationSolution} />
            </NotationGroup>
          }
          sidebar={<Score statistics={statistics} />}
        />
        <Paragraph>
          Mehr über Hölderlins Einsatz von Vokalen und Konsonanten erklärt der
          Sprechkünstler Christian Reiner in diesem Video...
        </Paragraph>
        <VideoPlayer src="kurse/ueber-hoelderlins-laute" poster={data.poster} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
